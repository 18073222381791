<template>
  <div id="wrapper" class="wrapper">
    <Header />
    <div class="content">
      <LoadingScreen loadingObject="page" />
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const Header = defineAsyncComponent(() => import('@/layout/Header'));
const Footer = defineAsyncComponent(() => import('@/layout/Footer'));
const LoadingScreen = defineAsyncComponent(() => import('@/components/LoadingScreen'));

export default {
  name: "Index",
  data() {
    return {
    }
  },
  components: {
    Header,
    Footer,
    LoadingScreen
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>

</style>
